<template>
  <div class="addResourceOrder subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>发布产能资源单</p>
      </div>
      <div class="addButton">
        <el-button type="danger" @click="showAddDialog()">发布产能资源单</el-button>
      </div>
    </div>
    <div class="productList">
      <el-table :data="goodsDatas" border>
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="name" label="商品分类"></el-table-column>
        <el-table-column prop="origin" label="产地"></el-table-column>
        <el-table-column prop="warehouse" label="仓库"></el-table-column>
        <el-table-column prop="price" label="单价"></el-table-column>
        <el-table-column prop="quantity" label="数量"></el-table-column>
        <el-table-column prop="lastPayDay" label="交货周期"></el-table-column>
        <el-table-column label="操作" width="85">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 1 || scope.row.status == 3"
              type="danger"
              size="mini"
              @click="cancelOrder(scope.row)"
            >撤单</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      ></el-pagination>
    </div>
    <el-dialog v-dialogDrag title="发布产能资源单" :visible.sync="dialogVisible" width="600px">
      <div class="addResourceOrderForm">
        <el-form ref="form" :rules="rules" :model="form" label-width="100px">
          <el-form-item label="商品" prop="commodityId">
            <el-select class="width-100" v-model="form.commodityId" placeholder="请选择商品">
              <el-option
                v-for="item in goodsOptions"
                :key="item.commodityId"
                :label="item.name"
                :value="item.commodityId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="单价" prop="price">
            <el-input  v-model="form.price"></el-input>
          </el-form-item>
          <el-form-item label="数量" prop="quantity">
            <el-input v-model="form.quantity" placeholder="请输入数量"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmAddResourceOrder()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      goodsDatas: [
        // {
        //   name: "钢材",
        //   origin: "天津",
        //   warehouse: "天津仓库",
        //   price: "10000.00 RMB",
        //   quantity: "30吨",
        //   lastPayDay: "7天"
        // },
        // {
        //   name: "钢材",
        //   origin: "天津",
        //   warehouse: "天津仓库",
        //   price: "10000.00 RMB",
        //   quantity: "30吨",
        //   lastPayDay: "7天"
        // },
        // {
        //   name: "钢材",
        //   origin: "天津",
        //   warehouse: "天津仓库",
        //   price: "10000.00 RMB",
        //   quantity: "30吨",
        //   lastPayDay: "7天"
        // }
      ],
      dialogVisible: false,
      goodsOptions: [
        // {
        //   commodityId: "选项1",
        //   name: "钢材"
        // },
        // {
        //   commodityId: "选项2",
        //   name: "木材"
        // }
      ],
      form: {
        commodityId: null,
        price: null,
        quantity: null
      },
      rules: {
        commodityId: [
          { required: true, message: "请选择商品", trigger: "change" }
        ],
        price: [
          { required: true, message: "请输入商品单价", trigger: "change" }
        ],
        quantity: [
          { required: true, message: "请输入商品数量", trigger: "change" }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    this.getDatas();
    this.getCommodityDatas();
  },
  methods: {
    getCommodityDatas() {
      protocolFwd.param_selectLtCommodityList.param.firmId = this.sessionInfoGetter.firmId;
      protocolFwd.param_selectLtCommodityList.param.status = 3;
      http.postFront(protocolFwd.param_selectLtCommodityList).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.goodsOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_querySellerOrderTwo.param.page = this.current - 1;
      protocolFwd.param_querySellerOrderTwo.param.size = this.pageSize;
      protocolFwd.param_querySellerOrderTwo.param.firmId = this.sessionInfoGetter.firmId;
      http.postFront(protocolFwd.param_querySellerOrderTwo).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.goodsDatas = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    showAddDialog() {
      this.dialogVisible = true;
    },
    confirmAddResourceOrder() {
      protocolFwd.param_sellOrderTwo.param = this.form;
      protocolFwd.param_sellOrderTwo.param.type = 1;
      http.postFront(protocolFwd.param_sellOrderTwo).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.dialogVisible = false;
          this.$EL_MESSAGE(message);
          this.getDatas();
        } else {
          this.$EL_MESSAGE(message);
        }
      });
      // 演示用
      // this.dialogVisible = false;
      // this.$EL_MESSAGE("资源单添加成功");
    },
    cancelOrder(item) {
      this.$confirm("请确认是否撤单？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          protocolFwd.param_undoSellOrderTwo.param.sellOrderId =
            item.sellOrderId;
          http.postFront(protocolFwd.param_undoSellOrderTwo).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.getDatas();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
          // 演示用
          // this.$EL_MESSAGE("撤单成功");
        })
        .catch(() => {});
    }
  }
};
</script>
<style scoped>
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.subPageTitle .addButton {
  float: right;
}
.productList {
  margin-top: 20px;
}
.subPage .pagination {
  margin-top: 15px;
}
/* 头像 开始 */
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.submitIdentification {
  text-align: center;
}
/* 头像 结束 */
</style>
